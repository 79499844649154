body {
  background: url(images/platform-79th-bg.jpg) no-repeat center center fixed;
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.5);
  background-blend-mode: multiply;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1 0 auto;
}

.app-header {
  margin-top: 1rem;
}

.byline {
  color: #ffffff;
  margin-top: 1rem;
  font-size: 1.25rem;
}

.logo {
  width: 25vw;
}

.plain-text {
  text-align:left;
  font-size: 1rem;
}

.page-footer {
  flex-shrink: 0;
  padding-bottom: 2rem;
}

.footer-logo {
  width: 12vw;
}

a, a:active {
  color: red;
}

a:hover {
  color: #ff3333;
  text-decoration: none;
}

a:visited {
  color: #993333;
}

.ios-badge {
  width: 9vw;
}

.badge-box {
  margin: 2vw 0vw 5vw 0vw;

}

.desc {
  margin: 4vw 2vw;
}

@media screen and (min-device-width : 375px)
and (max-device-width : 812px) {
  .ios-badge {
    width: 40vw;
  }

  .logo {
    width: 60vw;
  }

  .footer-logo {
    margin-top: 10vw;
    width: 28vw;
  }
}
