body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  display: flex;
  flex-direction: column;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  height: 100vh;
}

.app {
  height: 80%;
  flex-grow: 1;
}

.page-footer {
  width: 100%;
  text-align: center;
  flex-shrink: 0;
}

@media only screen and (max-device-width: 481px) {
  body {
    font-size: 1rem;
  }

  h1 {
    font-size: 1.8rem;
  }

  .ios-badge {
    width: 30vw  !important;
  }

  .footer-logo {
    width: 35vw  !important;
  }

  .logo {
    width: 70vw !important;
  }
}


